import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Footer from "../../../components/Footer";
import GoogleMapReact from "google-map-react";
import BookingCard from "../../../components/BookingCard";
import mapStyle from "./mapStyle";
import MapMarker from "../../../components/MapMarker";
import { getMidPoint } from "../../../utils/getMidpoint";
import {useBooking} from "../../../providers/BookingProvider";

const BookingDetails = () => {
  const defaultProps = {
    center: {
      lat: 9.654874,
      lng: 123.864124,
    },
    zoom: 16,
  };
  const { bookingId } = useParams();
  const navigate = useNavigate();

  const mapRef = useRef(null);
  const { booking:bookingDetails, driverLocation, fetchBookingDetails, statusText } = useBooking();

  useEffect(() => {
    (async () => {
      try {
        await fetchBookingDetails(bookingId)
            .then((booking) => {
              const midPoint = getMidPoint(
                  booking?.attributes?.origin?.latitude,
                  booking?.attributes?.origin?.longitude,
                  booking?.attributes?.destination?.latitude,
                  booking?.attributes?.destination?.longitude
              );

              if (mapRef.current) {
                mapRef.current.panTo(midPoint);
              }
            });
      } catch (error) {
        console.log(error.response);
        navigate("/expired-link");
      }
    })()
  }, [bookingId]);

  useEffect(() => {
    if (mapRef.current && bookingDetails) {
      const waypoints = bookingDetails?.attributes?.waypoints?.map((point) => ({
        lat: point.latitude,
        lng: point.longitude,
      }));
      const polyline = new window.google.maps.Polyline({
        path: waypoints,
        geodesic: true,
        strokeColor: "#DD1600",
        strokeOpacity: 0.7,
        strokeWeight: 5,
      });

      const originPoints = bookingDetails?.attributes?.waypoints[0]
      const originWaypoints = [
        {
          lat: bookingDetails?.attributes?.origin?.latitude,
          lng: bookingDetails?.attributes?.origin?.longitude,
        },
        {
          lat: originPoints.latitude,
          lng: originPoints.longitude
        }
      ]

      const lineSymbol = {
        path: "M 0,-1 0,1",
        strokeOpacity: 1,
        scale: 5,
        strokeColor: "#DD1600",
      };

      const originPolyline = new window.google.maps.Polyline({
        path: originWaypoints,
        geodesic: true,
        strokeColor: "#DD1600",
        strokeOpacity: 0,
        icons: [
          {
            icon: lineSymbol,
            offset: "0",
            repeat: "20px",
          },
        ],
        lineDashPhase: 10,
        lineDashPattern: [1, 8]
      });

      const destinationLastPoints = bookingDetails?.attributes?.waypoints[bookingDetails?.attributes?.waypoints.length - 1]
      const destinationWaypoints = [
        {
          lat: bookingDetails?.attributes?.destination?.latitude,
          lng: bookingDetails?.attributes?.destination?.longitude,
        },
        {
          lat: destinationLastPoints.latitude,
          lng: destinationLastPoints.longitude
        }
      ]
      const destinationPolyline = new window.google.maps.Polyline({
        path: destinationWaypoints,
        geodesic: true,
        strokeOpacity: 0,
        icons: [
          {
            icon: lineSymbol,
            offset: "0",
            repeat: "20px",
          },
        ],
        lineDashPhase: 10,
        lineDashPattern: [1, 8]
      });

      polyline.setMap(mapRef.current);
      originPolyline.setMap(mapRef.current);
      destinationPolyline.setMap(mapRef.current);

      const bounds = new window.google.maps.LatLngBounds();
      if (mapRef.current.fitBounds) {
        waypoints.forEach((point) => bounds.extend(point));
        mapRef.current.fitBounds(bounds, 20);
      }
    }
  }, [bookingDetails]);

  return (
    <>
      <div className="flex flex-col min-h-screen ">
        <div
          className="h-screen w-screen"
          style={{
            height: "calc(100vh - 75px)",
          }}
        >
          <GoogleMapReact
            ref={mapRef}
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
            }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
            options={{
              styles: mapStyle,
            }}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map }) => {
              mapRef.current = map;
            }}
          >
            {bookingDetails?.attributes?.origin && (
              <MapMarker
                lat={bookingDetails?.attributes?.origin?.latitude}
                lng={bookingDetails?.attributes?.origin?.longitude}
                imgUrl="/assets/pickup-marker.svg"
                style={{
                  height: 42,
                  width: 42,
                  transform: `translate(-50%, -50%)`,
                }}
              />
            )}

            {bookingDetails?.attributes?.destination && (
              <MapMarker
                lat={bookingDetails?.attributes?.destination?.latitude}
                lng={bookingDetails?.attributes?.destination?.longitude}
                imgUrl="/assets/dropoff-marker.svg"
                style={{
                  height: 42,
                  width: 42,
                  transform: `translate(-50%, -50%)`,
                }}
              />
            )}
            {driverLocation && (
              <MapMarker
                lat={driverLocation.latitude}
                lng={driverLocation.longitude}
                imgUrl="/assets/tricycle-marker.svg"
                style={{
                  height: 42,
                  width: 42,
                  transform: `translate(-50%, -50%) rotate(${driverLocation.heading}deg)`,
                }}
              />
            )}
          </GoogleMapReact>
          <BookingCard bookingDetails={bookingDetails} statusText={statusText}/>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default BookingDetails;
