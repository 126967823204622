import React from "react";
import MobileView from "./MobileView";
import WebView from "./WebView";

const BookingCard = (props) => {
  const { bookingDetails, statusText } = props;
  const isMobile = window.innerWidth <= 768;

  if (isMobile) {
    return <MobileView bookingDetails={bookingDetails} statusText={statusText} />;
  }
  return <WebView bookingDetails={bookingDetails} statusText={statusText} />;
};

export default BookingCard;
