import { Route, Routes } from "react-router-dom";

// error pages
import NotFoundPage from "../containers/error-pages/NotFoundPage";
import ExpiredLinkPage from "../containers/error-pages/ExpiredLinkPage";

// pages
import BookingDetails from "../containers/pages/BookingDetails";
import {BookingProvider} from "../providers/BookingProvider";

const MainRouter = () => {
  return (
    <Routes>
      {/* <Route path="/" element={<div>Hello World</div>} /> */}
        <Route path="/b/:bookingId" element={(
            <BookingProvider>
                <BookingDetails />
            </BookingProvider>
        )} />
      <Route path="/expired-link" element={<ExpiredLinkPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default MainRouter;
