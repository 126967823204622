export const getProfileImage = (user) => {
    const hasDefaultProfile = user.image.includes('blank.png')
    if (hasDefaultProfile) {
        const isVerifiedUser = user.is_verified ??
            user.account_verification.attributes.status === 'Approved'
        if (isVerifiedUser) {
            return user.selfie_photo?.thumbnail ?? user.profile_picture?.thumbnail ?? user.image
        }
    }

    return user.profile_picture?.thumbnail ?? user.image
}

export const getDriverProfileImage = (driver) => {
    const hasDefaultProfile = driver.image.includes('blank.png')
    if (hasDefaultProfile) {
        const isVerifiedDriver = driver.is_verified
        if (isVerifiedDriver) {
            return (driver.bio_detail?.photo?.thumbnail ?? (driver.profile_picture?.thumbnail ?? driver.image))
        }
    }

    return (driver.bio_detail?.photo?.thumbnail ?? (driver.profile_picture?.thumbnail ?? driver.image))
}
