import {createContext, use, useContext, useEffect, useState} from "react";
import {bindToPusherEvent, usePusher} from "../hooks/usePusher";
import axios from "../lib/axios";

export const BookingContext = createContext();

export const BookingProvider = ({children}) => {
    const [bookingId, setBookingId] = useState(null);
    const [booking, setBooking] = useState(null);
    const [driverLocation, setDriverLocation] = useState(null);
    const [statusText, setStatusText] = useState("Unknown");

    const pusher = usePusher();

    async function fetchBookingDetails(bookingId) {
        setBookingId(bookingId);
        return axios
            .get(
                `safety/v1/booking-details/${bookingId}?api_key=${process.env.REACT_APP_PEDI_API_KEY}`
            )
            .then((res) => {
                const booking = res.data.data;
                setBooking(booking);
                updateStatusText(booking);

                if (booking.relationships?.driver?.current_location) {
                    setDriverLocation(booking.relationships.driver.current_location);
                }

                return booking;
            });
    }

    function shouldPusherBeActive(booking) {
        if (!booking) return;
        const status = booking.attributes.status.toLowerCase()
        return status !== "completed" && status !== "cancelled";
    }

    function updateStatusText(booking) {
        if (!booking) return;
        const status = booking.attributes.status.toLowerCase();
        switch (status) {
            case "waiting":
                setStatusText("Waiting for driver");
                break;
            case "cancelled":
                setStatusText("Booking cancelled");
                break;
            case "booked":
                setStatusText("Driver is on the way");
                break;
            case "driver-arrived":
                setStatusText("Driver has arrived");
                break;
            case "pickup":
                setStatusText("Going to destination");
                break;
            case "scheduled":
                setStatusText("Waiting for pickup time");
                break;
            case "completed":
                setStatusText("Booking completed");
                break;
            default:
                setStatusText("");
                break;
        }
    }

    useEffect(() => {
        if (booking && !shouldPusherBeActive(booking) && pusher) {
            pusher.unbind_all();
            pusher.disconnect();
            console.debug("Disconnecting Pusher: Booking is already inactive.")
        }
    }, [statusText, booking, pusher])

    useEffect(() => {
        if (bookingId && pusher) {
            return bindToPusherEvent(pusher, `booking.${bookingId}`, 'booking', (data) => {
                const {data:newBooking} = data
                setBooking(newBooking);
                if (!driverLocation && newBooking.relationships.driver.current_location) {
                    setDriverLocation(newBooking.relationships.driver.current_location);
                }
                updateStatusText(newBooking);
            })
        }
    }, [pusher, bookingId]);

    useEffect(() => {
        if (pusher && booking && booking?.relationships?.driver) {
            return bindToPusherEvent(pusher, `location.driver.${booking.relationships.driver.id}`, 'location.driver', (data) => {
                let location = data.location;
                if (location == null || location.latitude == null || location.longitude == null) {
                    location = {latitude: 0, longitude: 0, heading: 1}
                }

                if (location) {
                    const status = booking.attributes.status.toLowerCase();
                    if (status !== "scheduled" || status !== "cancelled") {
                        setDriverLocation(location);
                    }
                }
            });
        }
    }, [pusher, booking]);

    return (
        <BookingContext.Provider
            value={{
                booking,
                driverLocation,
                statusText,
                fetchBookingDetails,
            }}>
            {children}
        </BookingContext.Provider>
    );
}

export const useBooking = () => {
    const bookingContext = useContext(BookingContext);

    if (bookingContext == null) {
        throw new Error('useBooking() called outside the provider');
    }

    return bookingContext;
};
