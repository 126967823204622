import {useEffect, useState} from "react";
import Pusher from "pusher-js";

export const usePusher = () => {
    const [pusher, setPusher] = useState(null);
    useEffect(() => {
        if (window.isPusherBeingInitialized) {
            if (!window.pusher_queue) {
                window.pusher_queue = [];
            }

            window.pusher_queue.push(setPusher);
            return;
        }

        if (!window.pusher) {
            window.isPusherBeingInitialized = true;
            try {
                Pusher.Runtime.createXHR = function () {
                    let xhr = new XMLHttpRequest()
                    xhr.withCredentials = true
                    return xhr
                }

                window.pusher = new Pusher("5w6evap70ymdniewjn2c", {
                    cluster: "ap1",
                    wsHost: "ws.pedi.ph",
                    wsPort: 443,
                    wssPort: 443,
                });
                console.debug("Pusher is now initialized", pusher);
                setPusher(window.pusher);

                if (window.pusher_queue) {
                    window.pusher_queue.forEach(setter => setter(window.pusher));
                    window.pusher_queue = [];
                }
            } catch (e) {
                window.alert("Failed to initialize a connection with the server. Please try again later.");
                console.error("Failed to initialize pusher", e);
            } finally {
                window.isPusherBeingInitialized = false;
            }
        }
    }, [pusher]);
    return pusher;
}

export const joinPusherChannel = (p, channelName, log_prefix) => {
    const prefix = log_prefix ?? `[Pusher:${channelName}]`;
    console.debug(`${prefix} Connecting to channel: ${channelName}`);
    const channel = p.subscribe(channelName);

    channel.bind('pusher:subscription_succeeded', () => {
        console.debug(`${prefix} Successfully subscribed to channel: ${channelName}`);
    })

    channel.bind('pusher:subscription_error', (err) => {
        console.debug(`${prefix} Failed to subscribe to channel: ${channelName}`, err);
    })
    return channel;
}

export const bindToPusherEvent = (
    p,
    channelName,
    eventName,
    callback
) => {
    const logPrefix = `[Pusher:${channelName}:${eventName}]`;
    const channel = joinPusherChannel(p, channelName, logPrefix);

    // Save the handler so it can be correctly unbound later.
    const handler = (data) => {
        console.debug(`${logPrefix} Received data:`, JSON.stringify(data, null, 2));
        callback(data);
    };

    channel.bind(eventName, handler);

    return () => {
        channel.unbind(eventName, handler);
        channel.unsubscribe();
    };
};
